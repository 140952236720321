var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-content-section"},[_c('div',{staticClass:"rate-header"},[_c('div',{staticClass:"rate"},[_c('div',{staticClass:"tableContainer"},[_c('div',[_c('v-simple-table',{staticClass:"first-table",attrs:{"dense":"","item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('thead',{staticClass:"table-first-col",style:({
                  backgroundColor: _vm.truck.enable ? '#E33029' : '#404040',
                })},[_c('tr',[_c('th',{staticClass:"pl-2 text-left rate-title rounded-l-lg",style:(_vm.mobileTitleStyles)},[_vm._v(" "+_vm._s(_vm.displayTitle)+" ")])])]),_c('tbody',{staticClass:"options"},_vm._l((_vm.rowsList),function(row,index){return _c('tr',{key:index,staticStyle:{"height":"39px"}},[_c('td',{staticClass:"mileage-title pl-3"},[_vm._v(_vm._s(row))])])}),0)]},proxy:true}])})],1),_c('div',{staticClass:"second-table"},[_c('v-simple-table',{staticClass:"d-flex",attrs:{"dense":"","item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._l((_vm.truck.service_offering_trucks),function(rate,rateIndex){return [(!!rate?.[_vm.truckRatePricingColumn])?_c('div',{key:rateIndex},[_c('thead',{staticClass:"table-content-col",style:({
                      backgroundColor: _vm.truck.enable ? '#E33029' : '#404040',
                    })},[_c('tr',[_c('th',{staticClass:"text-left",class:{
                          'rounded-r-lg':
                            rateIndex ===
                            _vm.truck.service_offering_trucks.length - 1,
                        }},[_c('v-btn',{staticClass:"ma-2 rate-btn",class:{
                            'selected-ratebtn':
                              rate[_vm.truckRateEnabledColumn] && _vm.truck.enable,
                          },attrs:{"outlined":"","disabled":!_vm.truck.enable}},[(rate[_vm.truckRateEnabledColumn])?_c('v-icon',{staticClass:"close-rate-btn",style:({
                              color: rate[_vm.truckRateEnabledColumn]
                                ? 'white'
                                : 'black',
                            }),on:{"click":function($event){return _vm.toggleColumn(
                                _vm.truckRateEnabledColumn,
                                _vm.truckRatePricingColumn,
                                _vm.truck.offering_service_region_id,
                                rateIndex
                              )}}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e(),_c('span',[_vm._v(_vm._s(rate.name))]),(!rate[_vm.truckRateEnabledColumn])?_c('v-icon',{staticClass:"add-rate-btn",on:{"click":function($event){return _vm.toggleColumn(
                                _vm.truckRateEnabledColumn,
                                _vm.truckRatePricingColumn,
                                _vm.truck.offering_service_region_id,
                                rateIndex
                              )}}},[_vm._v(" mdi-plus-circle ")]):_vm._e(),_c('v-tooltip',{attrs:{"max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.truck.enable)?_c('v-icon',_vm._g({staticClass:"option-info-btn"},on),[_vm._v(" mdi-information ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(rate.additional_specs))])])],1)],1)])]),(!!rate?.[_vm.truckRatePricingColumn])?_c('tbody',{staticClass:"options"},_vm._l((rate[
                        _vm.truckRatePricingColumn
                      ]),function(price,priceIndex){return _c('tr',{key:priceIndex,staticStyle:{"height":"39px"}},[_c('td',[_c('div',{staticClass:"ma-2 option-input d-flex align-center",class:{
                            'enable-option': _vm.truck.enable && price.enabled,
                          }},[(price.enabled)?_c('v-icon',{staticClass:"close-rate-btn",style:({
                              color:
                                _vm.truck.enable && price.enabled
                                  ? 'white'
                                  : 'grey',
                            }),on:{"click":function($event){return _vm.selectItem(
                                _vm.truckRateEnabledColumn,
                                _vm.truckRatePricingColumn,
                                _vm.truck.offering_service_region_id,
                                _vm.truckIndex,
                                rateIndex,
                                priceIndex,
                                false
                              )}}},[_vm._v(" mdi-close-circle-outline ")]):_vm._e(),_c('div',{staticClass:"d-flex",staticStyle:{"width":"inherit"}},[_c('span',{staticClass:"currency"},[_vm._v("$")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(price.price),expression:"price.price"}],staticClass:"ml-0 mileage-btn",class:{
                                'active-mileage-btn': !_vm.truck.enable,
                                'unactive-mileage-btn': _vm.truck.enable,
                              },attrs:{"type":"text","placeholder":_vm.customPlaceholdesPerRow?.[priceIndex]
                                  ? _vm.customPlaceholdesPerRow[priceIndex] || ''
                                  : '',"disabled":!_vm.truck.enable || !price.enabled},domProps:{"value":(price.price)},on:{"blur":function($event){return _vm.updateFormStatus(
                                  _vm.truckRatePricingColumn,
                                  price,
                                  _vm.truckIndex,
                                  priceIndex,
                                  rateIndex
                                )},"input":function($event){if($event.target.composing)return;_vm.$set(price, "price", $event.target.value)}}})]),(!price.enabled)?_c('v-icon',{class:{
                              'add-rate-btn_disabled':
                                !_vm.truck.enable || !price.enabled,
                              'add-rate-btn': _vm.truck.enable,
                            },on:{"click":function($event){return _vm.selectItem(
                                _vm.truckRateEnabledColumn,
                                _vm.truckRatePricingColumn,
                                _vm.truck.offering_service_region_id,
                                _vm.truckIndex,
                                rateIndex,
                                priceIndex,
                                true
                              )}}},[_vm._v(" mdi-plus-circle ")]):_vm._e()],1)])])}),0):_vm._e()]):_vm._e()]})]},proxy:true}])})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }