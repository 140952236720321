<template>
  <div class="right-content-section">
    <div class="rate-header">
      <div class="rate">
        <div class="tableContainer">
          <div>
            <v-simple-table class="first-table" dense item-key="name">
              <template v-slot:top>
                <thead
                  class="table-first-col"
                  :style="{
                    backgroundColor: truck.enable ? '#E33029' : '#404040',
                  }"
                >
                  <tr>
                    <th
                      class="pl-2 text-left rate-title rounded-l-lg"
                      :style="mobileTitleStyles"
                    >
                      {{ displayTitle }}
                    </th>
                  </tr>
                </thead>

                <tbody class="options">
                  <tr
                    v-for="(row, index) in rowsList"
                    :key="index"
                    style="height: 39px"
                  >
                    <td class="mileage-title pl-3">{{ row }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

          <div class="second-table">
            <v-simple-table dense item-key="name" class="d-flex">
              <template v-slot:top>
                <template
                  v-for="(rate, rateIndex) of truck.service_offering_trucks"
                >
                  <div :key="rateIndex" v-if="!!rate?.[truckRatePricingColumn]">
                    <thead
                      class="table-content-col"
                      :style="{
                        backgroundColor: truck.enable ? '#E33029' : '#404040',
                      }"
                    >
                      <tr>
                        <th
                          class="text-left"
                          :class="{
                            'rounded-r-lg':
                              rateIndex ===
                              truck.service_offering_trucks.length - 1,
                          }"
                        >
                          <v-btn
                            class="ma-2 rate-btn"
                            :class="{
                              'selected-ratebtn':
                                rate[truckRateEnabledColumn] && truck.enable,
                            }"
                            outlined
                            :disabled="!truck.enable"
                          >
                            <v-icon
                              v-if="rate[truckRateEnabledColumn]"
                              class="close-rate-btn"
                              :style="{
                                color: rate[truckRateEnabledColumn]
                                  ? 'white'
                                  : 'black',
                              }"
                              @click="
                                toggleColumn(
                                  truckRateEnabledColumn,
                                  truckRatePricingColumn,
                                  truck.offering_service_region_id,
                                  rateIndex
                                )
                              "
                            >
                              mdi-close-circle-outline
                            </v-icon>

                            <span>{{ rate.name }}</span>

                            <v-icon
                              v-if="!rate[truckRateEnabledColumn]"
                              class="add-rate-btn"
                              @click="
                                toggleColumn(
                                  truckRateEnabledColumn,
                                  truckRatePricingColumn,
                                  truck.offering_service_region_id,
                                  rateIndex
                                )
                              "
                            >
                              mdi-plus-circle
                            </v-icon>

                            <v-tooltip max-width="300" bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  class="option-info-btn"
                                  v-if="truck.enable"
                                  v-on="on"
                                >
                                  mdi-information
                                </v-icon>
                              </template>

                              <span>{{ rate.additional_specs }}</span>
                            </v-tooltip>
                          </v-btn>
                        </th>
                      </tr>
                    </thead>

                    <tbody
                      class="options"
                      v-if="!!rate?.[truckRatePricingColumn]"
                    >
                      <tr
                        v-for="(price, priceIndex) in rate[
                          truckRatePricingColumn
                        ]"
                        :key="priceIndex"
                        style="height: 39px"
                      >
                        <td>
                          <div
                            class="ma-2 option-input d-flex align-center"
                            :class="{
                              'enable-option': truck.enable && price.enabled,
                            }"
                          >
                            <v-icon
                              v-if="price.enabled"
                              @click="
                                selectItem(
                                  truckRateEnabledColumn,
                                  truckRatePricingColumn,
                                  truck.offering_service_region_id,
                                  truckIndex,
                                  rateIndex,
                                  priceIndex,
                                  false
                                )
                              "
                              :style="{
                                color:
                                  truck.enable && price.enabled
                                    ? 'white'
                                    : 'grey',
                              }"
                              class="close-rate-btn"
                            >
                              mdi-close-circle-outline
                            </v-icon>

                            <div class="d-flex" style="width: inherit">
                              <span class="currency">$</span>

                              <input
                                @blur="
                                  updateFormStatus(
                                    truckRatePricingColumn,
                                    price,
                                    truckIndex,
                                    priceIndex,
                                    rateIndex
                                  )
                                "
                                type="text"
                                class="ml-0 mileage-btn"
                                :placeholder="
                                  customPlaceholdesPerRow?.[priceIndex]
                                    ? customPlaceholdesPerRow[priceIndex] || ''
                                    : ''
                                "
                                v-model="price.price"
                                :class="{
                                  'active-mileage-btn': !truck.enable,
                                  'unactive-mileage-btn': truck.enable,
                                }"
                                :disabled="!truck.enable || !price.enabled"
                              />
                            </div>

                            <v-icon
                              v-if="!price.enabled"
                              @click="
                                selectItem(
                                  truckRateEnabledColumn,
                                  truckRatePricingColumn,
                                  truck.offering_service_region_id,
                                  truckIndex,
                                  rateIndex,
                                  priceIndex,
                                  true
                                )
                              "
                              :class="{
                                'add-rate-btn_disabled':
                                  !truck.enable || !price.enabled,
                                'add-rate-btn': truck.enable,
                              }"
                            >
                              mdi-plus-circle
                            </v-icon>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </div>
                </template>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.configure-region-rates {
  @import "@/assets/styles/ConfigureRegionRates.scss";

  font-family: "Outfit", sans-serif;
  background-color: #f4f4f4;
  padding: 25px;
}
</style>

<script>
export default {
  name: "RateTable",
  props: {
    displayTitle: { type: String, required: true },
    customPlaceholdesPerRow: { type: Array, required: false },
    truckRateEnabledColumn: { type: String, required: true },
    truckRatePricingColumn: { type: String, required: true },
    rowsList: { type: Array, required: true },
    truck: { type: Object, required: true },
    truckIndex: { type: Number, required: true },
    windowWidth: { type: Number, required: true },
  },
  inject: ["selectItem", "toggleColumn", "updateFormStatus"],
  computed: {
    mobileTitleStyles() {
      return {
        padding: this.windowWidth <= 600 ? "13px 0" : "",
      };
    },
  },
};
</script>
